<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <div style="float: right">
          <router-link :to="{ path: '/mypage/' }" class="btn bg2">목록으로</router-link>
        </div>
        <h2 class="h_title">이미지 검수 할당</h2>

        <h2 class="h_title2 mgT50">이미지 검수 작업자</h2>
        <div class="table_type1 mgT10">
          <table>
            <colgroup>
              <col width="5%" />
              <col width="5%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="30%" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">선택</th>
                <th scope="col">번호</th>
                <th scope="col">아이디</th>
                <th scope="col">이름</th>
                <th scope="col">소속</th>
                <th scope="col">할당 관리</th>
                <th scope="col">할당량</th>
                <th scope="col">검수량</th>
                <th scope="col">승인</th>
                <th scope="col">반려</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="5"></td>
                <th>할당가능 : {{ assign_num }}</th>
                <th>{{ total_assign }}</th>
                <th>{{ total_work }}</th>
                <th>{{ total_confirm }}</th>
                <th>{{ total_reject }}</th>
              </tr>

              <tr v-if="member_list.length == 0">
                <td colspan="11">
                  <span>배정된 검수자가 없습니다.</span>
                </td>
              </tr>
              <tr v-for="(m, index) in member_list" :key="m.no">
                <td>
                  <input type="checkbox" />
                </td>
                <td>{{ member_numbering(index) }}</td>
                <td>{{ m.userid }}</td>
                <td>{{ m.username }}</td>
                <td>{{ m.class_name }}</td>
                <td>
                  <input type="number" v-bind:id="'member' + m.no" />&nbsp;&nbsp;&nbsp;
                  <a @click="assign(m.no)" class="btn size1">할당하기</a>&nbsp;&nbsp;
                  <a @click="assign_cancel(m.no)" class="btn size1 bg3">할당취소</a>
                </td>
                <td>{{ m.assign_num }}</td>
                <td>{{ m.work_num }}</td>
                <td>{{ m.work_confirm }}</td>
                <td>{{ m.work_reject }}</td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <th colspan="5">
                </th>
                <th>합계</th>
                <th>{{ total_assign }}</th>
                <th>{{ total_work }}</th>
                <th>{{ total_confirm }}</th>
                <th>{{ total_reject }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- //table_type -->
      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: "ImgConfirmAssign",
  data: function () {
    return {
      member_list: [],
      assign_num: 0,
      total_assign: 0,
      total_work: 0,
      total_reject: 0,
      total_confirm: 0,
    };
  },
  components: {
    Header,
  },
  methods: {
    member_list_load() {
      axios({
        url: config.apiurl + "?part=image&mode=confirm_assign_list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
        },
      }).then((response) => {
        var d = response.data;
        console.log(d);
        this.member_list = d.list;
        this.assign_num = d.assign_num;
        this.total_assign = d.total_assign;
        this.total_work = d.total_work;
        this.total_reject = d.total_reject;
        this.total_confirm = d.total_confirm;
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
    member_numbering(num) {
      return this.member_list.length - num;
    },
    assign(mno) {
      // 할당하기
      var numid = document.getElementById("member" + mno.toString());
      var num = Number(numid.value);

      if (num >= 1 && num <= this.assign_num) {
        axios({
          url: config.apiurl + "?part=image&mode=confirm_assign",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            mno: mno,
            num: num,
          },
        }).then((response) => {
          console.log(response.data);
          alert(num + "개가 할당되었습니다.");
          this.member_list_load();
        }).catch(function (error) {
          alert("오류가 발생했습니다...(2)");
          console.log("에러야!");
          console.log(error);
        });
      } else {
        alert("1개부터 " + this.assign_num + "개까지 할당할 수 있습니다.");
      }
    },
    assign_cancel(mno) {
      // 할당취소
      if (confirm("정말로 할당된 작업을 반환하시겠습니까? 이 작업은 돌이킬 수 없습니다")) {
        axios({
          url: config.apiurl + "?part=image&mode=confirm_assign_cancel",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            mno: mno,
          },
        }).then((response) => {
          let d = response.data;
          console.log(d);
          alert(d.cancel_count + "개의 작업물이 반환되었습니다.");
          this.member_list_load();
        }).catch(function (error) {
          alert("오류가 발생했습니다...(3)");
          console.log("에러야!");
          console.log(error);
        });
      }
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 23);
    this.member_list_load();
  },
};
</script>