<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <div class="view_top">
          <h2 class="h_title">POI 정보</h2><a href="/img_confirm_list" class="btn size1">목록보기</a>
        </div>

        <!-- table_type -->
        <div class="table_type1">
          <table>
            <colgroup>
              <col width="8%">
              <col>
              <col>
              <col>
            </colgroup>
            <thead>
              <th scope="col">번호</th>
              <th scope="col">분류</th>
              <th scope="col">이름</th>
              <th scope="col">주소</th>
            </thead>
            <tbody>
              <tr>
                <td>{{ confirm_info.poi_no }}</td>
                <td>{{ confirm_info.poi_type }}</td>
                <td>{{ confirm_info.poi_name }}</td>
                <td>{{ confirm_info.poi_address }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- //table_type -->
        <h3 class="mgT50 h_title">업로드 이미지</h3>
        <div class="gd-1">
          <ul v-if="img_list.length == 0">
            등록된 이미지가 없습니다.
          </ul>
          <ul v-else>
            <li v-for="(img, index) in img_list" :key="img.no">
              <p class="image_desc">{{ image_std[index] }}</p>
              <div class="img">
                <img :src="'http://' + img.img_url" alt="">
              </div>
            </li>
          </ul>
        </div>

        <h3 class="mgT50 h_title">반려사유</h3>
        <textarea id="rr" name="rr" class="textareabox" placeholder="반려를 하시려면 사유를 적어주세요."
          v-model="confirm_info.reject_reason"></textarea>

        <div v-if="confirm_info.status == 2" class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <a href="#" @click="reject()" class="btn bg3">반려</a>
          <a href="#" @click="confirm()" class="btn bg2">검수완료</a>
        </div>
      </div>
      <!-- //contents -->
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: 'ImgConfirmView',
  data() {
    return {
      confirm_info: "",
      img_list: [],
      image_std: [],
    };
  },
  components: {
    Header
  },
  methods: {
    confirm_load() { // 검수 정보 불러오기
      axios({
        url: config.apiurl + "?part=image&mode=confirm_list2",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          ino: this.$route.params.img_no
        },
      }).then((response) => {
        let d = response.data;
        console.log(d)
        this.image_std = d.image_std;
        this.confirm_info = d.confirm_info;
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
    img_load() { // 이미지 정보 불러오기
      axios({
        url: config.apiurl + "?part=image&mode=image_list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          ino: this.$route.params.img_no
        },
      }).then((response) => {
        this.img_list = response.data.image_list;
        // console.log(this.img_list);
      }).catch(function (error) {
        alert("오류가 발생했습니다...(2)");
        console.log("에러야!");
        console.log(error);
      });
    },
    reject() { // 반려
      if (confirm("반려 하시겠습니까?")) {
        var rr = document.querySelector('#rr').value;
        axios({
          url: config.apiurl + "?part=image&mode=confirm_state",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            ino: this.$route.params.img_no,
            state: '-1',
            rr: rr
          },
        }).then((response) => {
          console.log(response);
          this.$router.push({ name: "ImgConfirmList" });
        }).catch(function (error) {
          alert("오류가 발생했습니다...(3)");
          console.log("에러야!");
          console.log(error);
        }); console.log("!");
      }
    },
    confirm() { // 검수완료
      if (confirm("검수완료 하시겠습니까?")) {
        axios({
          url: config.apiurl + "?part=image&mode=confirm_state",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            ino: this.$route.params.img_no,
            state: 3
          },
        }).then((response) => {
          console.log(response);
          this.$router.push({ name: "ImgConfirmList" });
        }).catch(function (error) {
          alert("오류가 발생했습니다...(4)");
          console.log("에러야!");
          console.log(error);
        });
      }
    }
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 22);
    this.confirm_load();
    this.img_load();
  }
};
</script>