<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <h2 class="h_title">
          이미지 검수 목록
        </h2>
        <div class="btn_box">
          <a v-bind:class="{ 'btn bg4': confirm_o, 'btn bg': !confirm_o }" @click="toggle_confirm('o')">검수한
            이미지</a>&nbsp;
          <a v-bind:class="{ 'btn bg4': confirm_x, 'btn bg': !confirm_x }" @click="toggle_confirm('x')">검수하지 않은 이미지</a>
        </div>
        <div class="search_wrapper mgT10">
          <input type="number" class="inputbox" style="width:260px;" v-model="search_poi" placeholder="POI 번호로 검색" />
          &nbsp;
          <a @click="doSearch2()" class="btn bg">검색</a>
        </div>
        <div class="search_wrapper mgT10">
          <input type="text" class="inputbox" @input="doSearch" placeholder="검색어를 입력하세요." />
        </div>
        <!-- table_type -->
        <div class="table_type1 mgT20">
          <table>
            <colgroup>
              <col width="6%" />
              <col width="6%" />
              <col />
              <col />
              <col width="8%" />
              <col width="8%" />
              <col width="10%" />
            </colgroup>
            <thead>
              <th scope="col">번호</th>
              <th scope="col">POI번호</th>
              <th scope="col">이름</th>
              <th scope="col">주소</th>
              <th scope="col">이미지 업로더</th>
              <th scope="col">상태</th>
              <th scope="col">관리</th>
            </thead>
            <tbody>

              <tr v-for="(c, index) in confirm_list" :key="c.no">
                <td>{{ confirm_numbering(index) }}</td>
                <td>{{ c.poi_no }}</td>
                <td class="txL">{{ c.poi_name }}</td>
                <td class="txL">{{ c.poi_address }}</td>
                <td v-bind:class="{ 'wasco': c.cno == 5 }">{{ c.username }}</td>

                <td>
                  <span v-if="c.status == 2" class="co_state">검수대기</span>
                  <span v-if="c.status == 3" class="co_state1">검수완료</span>
                  <span v-if="c.status == -1" class="co_state3">반려</span>
                </td>
                <td>
                  <router-link v-if="c.status == 2" :to="{ path: '/img_confirm_view/' + c.no }" class="btn size1">이미지검수
                  </router-link>
                  <router-link v-else :to="{ path: '/img_confirm_view/' + c.no }" class="btn size1">확인</router-link>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <div class="page_box mgT10">
          page : <input type="text" class="page" v-model="page" @input="paging($event, true)"> / {{ page_total }}
          &nbsp;
          <a @click="paging(-1)" class="btn size1">이전</a>
          &nbsp;
          <a @click="paging(1)" class="btn size1">다음</a>
        </div>
        <!-- //table_type -->
      </div>
      <!-- //contents -->
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: 'ImgConfirmList',
  data() {
    return {
      search: "",
      search_now: "",
      search_queue: false,
      search_poi: "",

      confirm_list: [],
      page: 1,
      page_total: 1,
      limit: 10,
      poi_num: 1,
      confirm_o: true,
      confirm_x: true,
    };
  },
  components: {
    Header
  },
  methods: {
    toggle_confirm(ox) {
      if (ox == 'o') {
        if (!this.confirm_o || this.confirm_x) {
          this.confirm_o = !this.confirm_o;
          this.confirm_list_load();
        }
      }
      else if (ox == 'x') {
        if (!this.confirm_x || this.confirm_o) {
          this.confirm_x = !this.confirm_x;
          this.confirm_list_load();
        }
      }
    },
    confirm_list_load(poi_no = 0) { // 검수 리스트 불러오기
      // let confirm = "";
      // if (this.confirm_o == !this.confirm_x) {
      //   if (this.confirm_o) confirm = 1;
      //   if (this.confirm_x) confirm = 2;
      // }
      let confirm = "";
      let search = "";
      if (poi_no == 0) {
        if (this.confirm_o == !this.confirm_x) {
          if (this.confirm_o) confirm = 1;
          if (this.confirm_x) confirm = 2;
        }

        this.poi_list = [];
        if (this.search !== "") {
          search = this.search;
        }
      }

      axios({
        url: config.apiurl + "?part=image&mode=confirm_list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          page: this.page,
          limit: this.limit,
          confirm: confirm,
          search: search,
          poi_no: poi_no,
        },
      }).then((response) => {
        let d = response.data;
        console.log(d);
        this.poi_num = d.poi_num;
        this.page_total = d.page_total;
        this.confirm_list = d.confirm_list;
        common.set_page_info(this, this.$route.name, this.page, search);
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
    confirm_numbering(num) { // 검수 목록 번호 세팅
      if (this.page < 1) {
        return this.poi_num - num;
      } else if (this.page >= 1 && this.page <= this.page_total) {
        return this.poi_num - ((this.page - 1) * this.limit) - num;
      } else {
        return this.poi_num - ((this.page_total - 1) * this.limit) - num;
      }
    },
    paging(count, to = false) { // 리스트 페이징
      var c = 1;
      if (to) {
        c = Number(count.target.value);
      } else {
        c = this.page + count;
      }

      if (c >= 1 && c <= this.page_total) {
        this.page = c;
      }
      this.confirm_list_load();
    },
    doSearch: function (e) {
      this.search = e.target.value;
      clearTimeout(this.search_queue);
      this.search_queue = setTimeout(() => {
        this.search_now = this.search;
        this.page = 1;
        this.confirm_list_load();
      }, 250);
    },
    doSearch2() {
      this.confirm_list_load(this.search_poi);
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 22);
    await common.set_page(this);
    this.confirm_list_load();
  }
};
</script>


<style>
.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  ;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}

.wasco{
  color:rgb(69, 69, 252) !important;
}
</style>